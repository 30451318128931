.App {
  text-align: center;
}

li{
  text-align: left;
}
p{
  text-align: left;
}
